import "./App.css";
import Login from "./login/Login";
import Header from "./Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Listing from "./pages/Listing";
import Feedbacks from "./pages/Feedbacks";
import Users from "./pages/Users";
import { ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import NotFoundPage from "./pages/NotFoundPage";

function App() {
  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <Header />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="listing" element={<Listing />} />
          <Route path="feedbacks" element={<Feedbacks />} />
          <Route path="users" element={<Users />} />
          {/* 404 route */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
