import React from 'react';

const formatTextToHTML = (text) => {

    const removeUnwantedCharacters = (inputString) => {
        const lines = inputString.split("\n");

        const cleanedLines = lines.map((line) => {
            const cleanedLine = line.replace(/^\s*[-•]\s*/, "");
            return cleanedLine;
        });

        return cleanedLines.join("\n");
    };

    const lines = text.trim().split('\\n');
    const formattedContent = [];
    let currentDiv = [];
    let line2;
    lines.forEach((line, index) => {
        line2 = line.replace('\\', "").replace('"', "");
        if ((line2.startsWith('Day') && line2.match(/Day (\d+):/)) || line2.startsWith('*') || line2.toLowerCase().startsWith('total cost') || line2.toLowerCase().startsWith('total approximate cost') || line2.toLowerCase().startsWith('approximate cost') || line2.toLowerCase().startsWith('approximate total cost:')) {
            if (currentDiv.length > 0) {
                formattedContent.push(
                    <div className='app-main__inner col-xl-12 mx-auto' key={index}>
                        <div className='row align-items-center title_head'>
                            <div className='col-xl-12 col-lg-10 col-md-10 col-sm-10 col-12 col-arrival'>
                                <h2>{removeUnwantedCharacters(currentDiv[0])}</h2>
                            </div>
                        </div>
                        <div className='row iti_content pb-2'>
                            <div className='col-xl-12 col-lg-12 iti_main'>
                                {currentDiv.slice(1)}
                            </div>
                        </div>
                    </div>
                );
                currentDiv = [];
            }
            currentDiv.push(line2.replace('**', "").replace('**', ""));
        } else if (line2 !== "") {
            currentDiv.push(<li key={index}>{removeUnwantedCharacters(line2)}</li>);
        }
    });

    // Push the final div if there's content left
    if (currentDiv.length > 0) {
        formattedContent.push(
            <div className='app-main__inner col-xl-12 mx-auto' key={lines.length}>
                <div className='row align-items-center title_head'>
                    <div className='col-xl-12 col-lg-10 col-md-10 col-sm-10 col-12 col-arrival'>
                        <h2>{removeUnwantedCharacters(currentDiv[0])}</h2>
                    </div>
                </div>
                <div className='row iti_content pb-2'>
                    <div className='col-xl-12 col-lg-12 iti_main'>
                        {currentDiv.slice(1)}
                    </div>
                </div>
            </div>
        );
    }

    return formattedContent;
};

const ChatResponse = ({ responseText }) => {
    const formattedContent = formatTextToHTML(responseText);

    return (
        <div className='app-main__inner col-xl-9 mx-auto'>
            {formattedContent}
        </div>
    );
};

export default ChatResponse;
