import { useEffect, useRef, useState } from "react";
import Sidemenu from "./menu/sidemenu";
import { showErrorMsg, showSuccessMsg } from "../utils/notification";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ChatResponse from '../components/ChatResponse';

import {
  getFeedbacks,
  updateApproved,
} from "../modules/listing/actions";

const API_STAGE = process.env.REACT_APP_BASE_STAGE_URL;
const Feedbacks = () => {
  const [propertyList, setProperyList] = useState([]);

  const [isFetchingProperty, setIsFetchingProperty] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    getAllProperty();
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = (option) => {
    setSelectedOption(option);
    setShow(true);
  };

  const getAllProperty = () => {
    setIsFetchingProperty(true);
    getFeedbacks().then(
      (res) => {
        let list = res.data;
        console.log(res.data);
        setProperyList(list);
        setIsFetchingProperty(false);
      },
      (err) => {
        setIsFetchingProperty(false);
      }
    );
  };

  return (
    <>
      <Sidemenu />
      <div className="relative md:ml-64 bg-blueGray-100 mt-30 px-4 ">
        <nav className="w-full bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center">
          <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap">
            <h3>Reviews</h3>
            {/* <div className="flex-col md:flex-row list-none items-center hidden md:flex def-btn">
              Add new property
            </div> */}
          </div>
        </nav>

        <div className="relative bg-lightBlue-600 pb-32 dash-table mt-10">
          <div className="block w-full overflow-x-auto">
            {isFetchingProperty && (
              <h3 className="text-center">Loading Data...</h3>
            )}
            {!isFetchingProperty && (
              <table className="items-center w-full bg-transparent border-collapse">
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Trip</th>
                    <th>Website Rating</th>
                    <th>Trip Rating</th>
                    <th>Comments</th>
                  </tr>
                </thead>
                <tbody>
                  {propertyList.map((data, index) => (
                    <tr key={index}>
                      <td>{data.User?.full_name}</td>
                      <td><a href="#" onClick={() => handleShow(data.Trip)}>{data.Trip?.place}</a></td>
                      <td>{data.website_rating} </td>
                      <td>{data.rating} </td>
                      <td>{data.comments}</td>

                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered className='itinerary_modal'>
        <Modal.Header closeButton>
          <Modal.Title>Your {selectedOption?.place} Trip <button type='button' className='plain-btn d-none'>Remove this itinerary</button></Modal.Title>
        </Modal.Header>
        <Modal.Body className='iti_detail_dash'>
          {selectedOption && <ChatResponse responseText={selectedOption?.itinearay} />}
        </Modal.Body>


        <Modal.Footer className='d-none'>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Feedbacks;
