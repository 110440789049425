import { useEffect, useRef, useState } from "react";
import Sidemenu from "./menu/sidemenu";
import { showErrorMsg, showSuccessMsg } from "../utils/notification";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ChatResponse from '../components/ChatResponse';
import {
  getProperties,
  updateApproved,
} from "../modules/listing/actions";

const API_STAGE = process.env.REACT_APP_BASE_STAGE_URL;
const Listing = () => {
  const [propertyList, setProperyList] = useState([]);

  const [isFetchingProperty, setIsFetchingProperty] = useState(false);

  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [modEvt, setModEvt] = useState(null);
  const [propData, setPropData] = useState(null);
  const [isLoading, setIsloading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (option) => {
    setSelectedOption(option);
    setShow(true);
  };

  useEffect(() => {
    getAllProperty();
  }, []);

  const getAllProperty = () => {
    setIsFetchingProperty(true);
    getProperties().then(
      (res) => {
        let list = res.data;
        console.log(res.data);
        setProperyList(list);
        setIsFetchingProperty(false);
      },
      (err) => {
        setIsFetchingProperty(false);
      }
    );
  };

  const statusChange = (event, data) => {
    setModEvt(event);
    setPropData(data);
    updateStatus(event, data.id);
  };

  const updateStatus = async (homeFlag, tripId) => {
    setIsloading(true);

    var urlencoded = new URLSearchParams();
    urlencoded.append("home_flag", homeFlag);
    urlencoded.append("propertyId", tripId);
    updateApproved(urlencoded).then(
      (res) => {
        setPropData(null);
        setModEvt(null);
        setShow(false);
        setIsloading(false);
        // console.log(res);
        showSuccessMsg("Updated successfully!");
        window.location.reload(false);
      },
      (err) => {
        setPropData(null);
        setModEvt(null);
        setShow(false);
        setIsloading(false);
        showErrorMsg("Something went wrong!");
      }
    );



  };

  return (
    <>
      <Sidemenu />
      <div className="relative md:ml-64 bg-blueGray-100 mt-30 px-4 ">
        <nav className="w-full bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center">
          <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap">
            <h3>All itineraries</h3>
            {/* <div className="flex-col md:flex-row list-none items-center hidden md:flex def-btn">
              Add new property
            </div> */}
          </div>
        </nav>

        <div className="relative bg-lightBlue-600 pb-32 dash-table mt-10">
          <div className="block w-full overflow-x-auto">
            {isFetchingProperty && (
              <h3 className="text-center">Loading Data...</h3>
            )}
            {!isFetchingProperty && (
              <table className="items-center w-full bg-transparent border-collapse">
                <thead>
                  <tr>
                    <th>Place</th>
                    <th>Nights</th>
                    <th>Budget</th>
                    <th>Trip type</th>
                    <th>Accomodation type</th>
                    <th>User</th>
                    <th>Itinerary</th>
                    <th>Show in home</th>
                  </tr>
                </thead>
                <tbody>
                  {propertyList.map((data, index) => (
                    <tr key={index}>
                      <td>{data.place}</td>
                      <td>{data.nights}</td>
                      <td>{data.budget} </td>
                      <td>{data.trip_type} </td>
                      <td>{data.hotel_type}</td>
                      <td>{data.User.full_name}</td>
                      <td><a href="#" onClick={() => handleShow(data)}>{data.place}</a></td>
                      <td>
                        <span className="price-sp">
                          <select
                            name="home_flag"
                            id={index}
                            onChange={(e) => statusChange(e.target.value, data)}
                            className={`form-control d-inline-flex price-select 
                            ${data.home_flag === 0
                                ? "border-warning text-warning"
                                : "border-success text-success"
                              }`}
                          >
                            <option
                              value="0"
                              selected={data.home_flag === 0 ? "selected" : ""}
                            >
                              No
                            </option>
                            <option
                              value="1"
                              selected={data.home_flag === 1 ? "selected" : ""}
                            >
                              Yes
                            </option>
                          </select>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered className='itinerary_modal'>
        <Modal.Header closeButton>
          <Modal.Title>Your {selectedOption?.place} Trip <button type='button' className='plain-btn d-none'>Remove this itinerary</button></Modal.Title>
        </Modal.Header>
        <Modal.Body className='iti_detail_dash'>
          {selectedOption && <ChatResponse responseText={selectedOption?.itinearay} />}
        </Modal.Body>


        <Modal.Footer className='d-none'>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Listing;
