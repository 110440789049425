import { getToken } from "../../utils/helper";
import axios from "axios";

const API_ENDPOINT = process.env.REACT_APP_BASE_URL;
/**
 *  Get all property
 */
export async function getProperties(url) {
  try {
    const token = getToken();
    const response = await axios({
      method: "get",
      url: `${API_ENDPOINT}/admintrips` + "?" + url,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + token,
      },
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

export async function getFeedbacks() {
  try {
    const token = getToken();
    const response = await axios({
      method: "get",
      url: `${API_ENDPOINT}/adminratings`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + token,
      },
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}
/**
 *  Get users
 */
export async function fetchUsers() {
  try {
    const token = getToken();
    const response = await axios({
      method: "get",
      url: `${API_ENDPOINT}/userlist`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + token,
      },
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}
export async function updateApproved(params) {
  try {
    const token = getToken();
    const response = await axios({
      method: "post",
      url: `${API_ENDPOINT}/editproperty`,
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + token,
      },
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}