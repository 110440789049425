import { useEffect, useRef, useState } from "react";
import Sidemenu from "./menu/sidemenu";
import { showErrorMsg, showSuccessMsg } from "../utils/notification";

import {
  fetchUsers,
} from "../modules/listing/actions";

const API_STAGE = process.env.REACT_APP_BASE_STAGE_URL;
const Users = () => {
  const [propertyList, setProperyList] = useState([]);

  const [isFetchingProperty, setIsFetchingProperty] = useState(false);

  const [show, setShow] = useState(false);
  const [modEvt, setModEvt] = useState(null);
  const [propData, setPropData] = useState(null);
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    getAllProperty();
  }, []);

  const getAllProperty = () => {
    setIsFetchingProperty(true);
    fetchUsers().then(
      (res) => {
        let list = res.data;
        console.log(res.data);
        setProperyList(list);
        setIsFetchingProperty(false);
      },
      (err) => {
        setIsFetchingProperty(false);
      }
    );
  };

  return (
    <>
      <Sidemenu />
      <div className="relative md:ml-64 bg-blueGray-100 mt-30 px-4 ">
        <nav className="w-full bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center">
          <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap">
            <h3>Users</h3>
            {/* <div className="flex-col md:flex-row list-none items-center hidden md:flex def-btn">
              Add new property
            </div> */}
          </div>
        </nav>

        <div className="relative bg-lightBlue-600 pb-32 dash-table mt-10">
          <div className="block w-full overflow-x-auto">
            {isFetchingProperty && (
              <h3 className="text-center">Loading Data...</h3>
            )}
            {!isFetchingProperty && (
              <table className="items-center w-full bg-transparent border-collapse">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Trips</th>
                  </tr>
                </thead>
                <tbody>
                  {propertyList.map((data, index) => (
                    <tr key={index}>
                      <td>{data.full_name}</td>
                      <td>{data.email}</td>
                      <td></td>

                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
